<template>
<b-card v-if="users" no-body class="card-company-table">
    <b-table :items="users" responsive :fields="fields" class="mb-0">
        <!-- Id -->
        <template #cell(id)="data">
            <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
        </template>
        <!-- CreatedAt Time -->
        <template #cell(createdAt)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.createdAt | moment("DD.MM.YYYY hh:mm")
          }}</span>
        </template>

        <!-- Status -->
        <template #cell(isActive)="data">
            <b-badge pill :variant="data.item.isActive?'light-success':'light-danger'">
                {{data.item.isActive?'AKTİF':'PASİF'}}
            </b-badge>
        </template>

        <!-- From User -->
        <template #cell(userName)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.student.firstName+ ' ' +data.item.student.lastName
          }}</span>
        </template>

              <!-- Class -->
        <template #cell(class)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.student.class}}</span>
        </template>

        <!-- Lesson -->
        <template #cell(lesson)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.lesson.name
          }}</span>
        </template>

        <!-- Note -->
        <template #cell(definition)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.definition
          }}</span>
        </template>

        <!-- Level -->
        <template #cell(status)="data">
            <b-badge pill v-bind:style="{backgroundColor:getStatusColor(data.item.status)}">
                {{getStatusText(data.item.status)}}
            </b-badge>
        </template>

        <!-- Actions -->
        <template #cell(actions)="data">
            <b-button variant="flat-warning" @click="openQuestion(data.item.question.url)">
                <feather-icon icon="EyeIcon" class="mr-50" />
                <span class="align-middle">Soru</span>
            </b-button>
            <b-button variant="flat-success" @click="openSolution(data.item.solution.url)">
                <feather-icon icon="EyeIcon" class="mr-50" />
                <span class="align-middle">Çözüm</span>
            </b-button>
            <b-button variant="flat-primary" @click="editReply(data.item)">
                <feather-icon icon="EditIcon" class="mr-50" />
                <span class="align-middle">Cevapla</span>
            </b-button>
        </template>
    </b-table>

    <!--Open Edit Gain-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="editGainPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Soru Cevapla</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Doğru Cevap" label-for="name">
                    <b-form-input id="textarea-default" v-model="editReplyRequest.answer" />
                </b-form-group>
                <b-form-group label="Açıklama" label-for="name">
                    <b-form-textarea id="textarea-default" v-model="editReplyRequest.definiton" rows="3" />
                </b-form-group>
                <b-form-group label="Çözüm videosu" label-for="name">
                    <b-form-file ref="file-input" v-model="solutionFile" class="mb-2" />
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="updateSolution">
                        Güncelle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
                <b-overlay :show="loading" no-wrap>
                </b-overlay>
            </b-form>
        </template>

    </b-sidebar>

</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BFormFile,
    BOverlay
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        BOverlay,
        BFormFile,
        ToastificationContent,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup
    },
    data() {
        return {
            loading: false,
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdAt', label: 'Kayıt Tarihi' },
                { key: 'isActive', label: 'Durum' },
                { key: 'status', label: 'İşlem Durumu' },
                { key: 'userName', label: 'Öğrenci' },
                { key: 'class', label: 'Şubesi' },
                { key: 'lesson', label: 'Ders' },
                { key: 'definiton', label: 'Öğrenci Notu' },
                { key: 'correct', label: 'Doğru Cevap' },
                { key: 'actions', label: 'İşlemler' },
            ],
            users: [],
            editGainPanel: false,
            editReplyRequest: {
                replyId: 0,
                answer: '',
                Definiton: '',
                file: null
            },
            solutionFile: []
        }
    },
    created() {
        this.getData();
    },
    methods: {
        openQuestion(data) {
            window.open(data, "Önizleme", 'fullscreen="yes"');
            // myWindow.document.write(data.url);
        },
        openSolution(data) {
            window.open(data, "Önizleme", 'fullscreen="yes"');
            // myWindow.document.write(data.url);
        },
        async getData() {
            var users = await this.$http.get("Teacher/Reply");
            this.users = users.data.data;
        },
        getStatusColor(status) {
            switch (status) {
                case 'Delivered':
                    return '#f45d22';
                case 'Waiting':
                    return '#3333ff';
                case 'Answered':
                    return '#17bf63';
                case 'NotAnswered':
                    return '#ff003a';
                case 'Canceled':
                    return '#ff003a';
            }
        },
        getStatusText(status) {
            switch (status) {
                case 'Delivered':
                    return 'Yeni';
                case 'Waiting':
                    return 'Yeni';
                case 'Answered':
                    return 'Cevaplandı';
                case 'NotAnswered':
                    return 'Cevaplanamadı';
                case 'Canceled':
                    return 'İptal Edildi';
            }
        },
        editReply(data) {
            this.editReplyRequest = {
                replyId: data.id,
                answer: data.correct,
                Definiton: data.definiton
            };
            this.editGainPanel = true;
        },
        updateSolution() {
            this.loading = true;
            var formData = new FormData();
            formData.append("replyId", this.editReplyRequest.replyId);
            formData.append("answer", this.editReplyRequest.answer);
            formData.append("definiton", this.editReplyRequest.definition);
            formData.append("file", this.solutionFile);
            this.$http.post("Teacher/AddSolutionReply", formData).then((data) => {
                    this.loading = false;
                    this.getData();
                    this.editGainPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Kazanım Güncellendi",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    this.loading = false;
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
